import * as React from "react";
import * as styles from "./about.module.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <div className={styles.page}>
        <a href="/" className={styles.back}>
          &larr; Accueil
        </a>
        <h1>Réalisations</h1>
        <h2>En bref</h2>
        <p>
          Aquarelliste professionnelle qui a acquis une solide expérience de son
          médium depuis plus de 20 ans. Impliquée activement dans les
          associations de son milieu artistique, elle participe à de nombreux
          symposiums et expositions en plus d’offrir des ateliers et
          démonstrations. Sa fougue aux pinceaux ne laisse personne indifférent.
        </p>

        <h3>Implication dans le milieu :</h3>

        <h4>Société Canadienne de l’Aquarelle (SCA) :</h4>

        <p>
          Après avoir été reçu comme membre signataire de la SCA en 2010, elle
          s’intéresse à la pérennité de la société. Elle en devient la
          présidente en 2012 pour un mandat de deux ans. Sous sa gouverne, de
          nombreux changements bénéfiques furent apportés. Aujourd'hui, elle est
          toujours impliquée dans l'organisation.
        </p>

        <h4>L'Eau-Vive :</h4>

        <p>
          Membre fondatrice de l’association, elle débute comme vice-présidente.
          Elle en devient la présidente un an plus tard. Durant les sept années
          de son mandat, elle organisera de nombreux ateliers de
          perfectionnement avec des membres signataires de la SCA pour le
          bénéfice de l’association. Comme héritage, elle laisse une
          organisation solide capable de prendre la relève. Aujourd'hui, elle
          est membre honoraire de l'association.
        </p>
        <h2>Expositions</h2>
        <ul>
          <li>
            Exposition collective "Eau Vive", Les Moulins La Fayette à
            Trois-Rivières : Avril 2022 à Juin 2022, Nov 2019 à Janv 2020
          </li>
          <li>
            Biennale de la Société Canadienne d’Aquarelle : 2022
            (Trois-Rivières), 2019 (Québec), 2017 (Alma)
          </li>
          <li>Société Canadienne d’Aquarelle à Niagara on the Lake : 2017</li>
          <li>Les chemins de l’eau à Lac Mégantic : 2016</li>
          <li>Galerie d’art du Presbytère de Champlain : 2016, 2013, 2012</li>
          <li>
            Centre de diffusion l’Œil Tactile à Trois-Rivières : 2016, 2015,
            2014, 2010, 2009
          </li>
          <li>Salon de la Société Canadienne de l’Aquarelle: de 2010 à 2016</li>
          <li>
            Centre culturel Pauline-Julien de Trois-Rivières (en solo) : 2013
          </li>
          <li>Hall de l’Université du Québec à Trois-Rivières : 2012</li>
          <li>
            Salon de coiffure Têtu à Outremont (en solo) : 2012, 2011, 2010
          </li>
          <li>Atelier TIRELOU à Champlain : 2010, 2009, 2008</li>
          <li>Petite semaine culturelle de Trois-Rivières : 2003 à 2009</li>
          <li>Maison Rodolphe Duguay : 2008, 2005</li>
          <li>Bibliothèque de Bécancour : 2007</li>
          <li>Bibliothèque de Trois-Rivières Ouest : 2007</li>
          <li>Moulin Seigneurial de Pointe-du-Lac : 2008</li>
        </ul>
        <h2>Symposiums</h2>
        <ul>
          <li>Rendez-vous des peintres de Ste-Flore : 2019</li>
          <li>Marée Haute de Champlain : 2019, 2018</li>
          <li>
            Rendez-vous des Arts de St-Narcisse : 2017, 2012, 2011, 2010, 2009,
            2008
          </li>
          <li>
            D’aquarelle de l’Eau-Vive à Trois-Rivières : 2022 (présidente
            d'honneur), 2018,2017, 2015, 2014 (présidente d'honneur)
          </li>
          <li>
            Des Arts Visuels du parc des chenaux à Trois-Rivières : 2013, 2012,
            2010, 2006, 2005
          </li>
          <li>Les Arts de la rue de Danville : 2013, 2012, 2011,</li>
          <li>30 ans en couleurs de St-Adèle (Chantecler) : 2013</li>
          <li>Gatineau en couleur : 2013</li>
          <li>Des Patriotes à St-Eustache : 2012</li>
          <li>La route des fleurs de Laval : 2012</li>
          <li>Sainte-Rose de Laval : 2012, 2011, 2010</li>
          <li>Couleurs urbaines de Granby : 2012</li>
          <li>Bois-des-Filions : 2012</li>
          <li>Jonquière : 2011</li>
          <li>Cap Santé riche en couleurs : 2011</li>
          <li>Domaine Joly-de-Lotbinière : 2011, 2010</li>
          <li>Ovation Aquarelle de Beauport : 2011, 2008</li>
          <li>Ile St-Quentin à Trois-Rivières : 2011, 2010</li>
          <li>Rendez-vous des peintres à Nicolet : 2011</li>
          <li>Plaines Couleurs à Québec : 2010</li>
          <li>St-Ulric : 2008</li>
        </ul>
        <h2>Prix et mentions</h2>
        <ul>
          <li>
            2013 Premier prix du jury au symposium du Parc des Chenaux de
            Trois-Rivières
          </li>
          <li>
            2011 Premier prix catégorie Professionnel Aquarelle au Domaine
            Joly-de-Lotbinière
          </li>
          <li>
            2007 Moulin Seigneurial de Pointe-du-Lac (mention pour
            l'originalité)
          </li>
        </ul>
      </div>
    </>
  );
};

export default IndexPage;
